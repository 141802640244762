.ant-select-tree-checkbox {
  display: none;
}

.ant-select-tree-treenode:not(.ant-select-tree-treenode-checkbox-checked):hover {
  background-color: #f5f5f5;
}

.ant-select-tree-treenode-checkbox-checked {
  background-color: #e6f7ff;
}

.ant-select-tree-treenode .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}

.ant-select-tree-treenode-checkbox-checked
  .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}

.ant-select-tree-switcher {
  color: #8d8d8d;
}

.ant-select-tree-switcher-icon:hover {
  background-color: white;
  box-shadow: 0 0 0 5px white;
  box-sizing: border-box;
  border-radius: 1px;
}

.ant-select-selection-item {
  cursor: pointer !important;
}
